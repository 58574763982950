<template>
  <div class="backShadow">
    <div v-if="!logging && !faultOnLogin" class="modal-container">
      <label class="textAverage">Login</label>
      <input type="email" placeholder="E-mail" class="textAverage" v-model="email"/>
      <input type="password" placeholder="Senha" class="textAverage" v-model="password" v-on:keyup.enter="login"/>
      <div class="forgotPassword">
        <router-link class="router-link" to="/ForgotPassword">Esqueceu a senha ?</router-link>
      </div>
      <div class="btn btnAverageSize btnTypeSave btnLogin" v-on:click="login" >Login</div>
    </div>
    <PopUp  msg="Efetuando login..." :show="logging" type="Loader"/>
    <PopUp  v-bind:msg="faultMsg" :show="faultOnLogin" type="Fault" :onOk="popUpOk"/>
  </div>
</template>

<script>
  import UserControl from '@/sys/Control/User/UserControl'
  import PopUp from '@/views/PopUp/PopUp.vue'
  import router from '@/router/index'
  export default {
    components:{PopUp},
    props:{
      path:{
        default:null
      }
    },
    data() {
      return {
        logging: false,
        faultOnLogin: false,
        faultMsg: null,
        email: '',
        password: ''
      }
    },
    methods:{
      login: function(){
        this.logging=true;
        UserControl.login(this.email,this.password).then(result=>{
          console.log(result);
          router.push({path:'/ListOfDevices'});
        }).catch(e=>{
          this.faultMsg=e;
          this.faultOnLogin=true;
          this.logging=false;
        });
      },
      popUpOk: function(){
        this.faultOnLogin=false;
      }
    }
  }
</script>

<style scoped>
  label{
    width: 300px;
    text-align: center;
    margin:0px 0px 0px 0px ;
  }

  input, input:before, input:after {
    user-select: initial;
  }

  input{
    height: 50px;
    width: 300px;
    background-color: #1e2227;
    border: none;
    outline: none;
    border-radius: 4px;
    text-align: center;
    margin:20px 0px 0px 0px ;
  }

  .btnLogin{
    width: 300px;
    margin:20px 0px 0px 0px ;
  }

  .router-link{
    text-decoration: none;
    color: white;
  }

  .forgotPassword{
    margin-top: 10px;
  }
</style>
